import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import(/* webpackChunkName: "login-home-welcome" */ './components/Login.vue')
const Home = () => import(/* webpackChunkName: "login-home-welcome" */ './components/Home.vue')
const Welcome = () => import(/* webpackChunkName: "login-home-welcome" */ './components/Welcome.vue')
const Error = () => import(/* webpackChunkName: "login-home-welcome" */ './components/Error.vue')

/*用户管理*/
const User = () => import(/* webpackChunkName: "user-right-roles" */ './components/users/User.vue')
const Role = () => import(/* webpackChunkName: "user-right-roles" */ './components/users/Role.vue')
const Module = () => import(/* webpackChunkName: "user-right-roles" */ './components/users/Module.vue')
const Password = () => import(/* webpackChunkName: "user-right-roles" */ './components/users/Password.vue')
//const ResetPwd = () => import('./components/ResetPwd.vue')//重置密码

/*学校首页*/
const HomePhoto = () => import( './components/publicity/HomePhoto.vue')
const Apply = () => import('./components/student/apply.vue')//预约报名
/*学校概况*/
const School = () => import('./components/publicity/School.vue')//学校简介
const AddSchool = () => import('./components/publicity/AddSchool.vue')
const Manage = () => import('./components/schoolProfile/manage.vue')//管理团队
const Sendword = () => import('./components/schoolProfile/sendword.vue')//校长寄语
const Course = () => import('./components/schoolProfile/course.vue')//课程体系
const Publicity = () => import('./components/schoolProfile/publicity.vue')//学校宣传片
const View = () => import('./components/schoolProfile/view.vue')//学校全景
/*校园动态*/
const News = () => import('./components/news/News.vue')
const AddNews = () => import('./components/news/AddNews.vue')
const Notice = () => import('./components/news/Notice.vue')
const AddNotice = () => import( './components/news/AddNotice.vue')
/*党建园地*/
const PartyShow = () => import('./components/party/partyShow.vue')//党员风采
// const PartyMien = () => import('./components/party/partyMien.vue')//党员风采-照片墙
const PartyWorld = () => import('./components/party/partyWorld.vue')//党员活动
const AddPartyWorld = () => import('./components/party/addPartyWorld.vue')//添加党员活动

/*办学成果*/
const SchoolHonour = () => import('./components/achievement/schoolHonour.vue')//学校荣誉
const ReportCard = () => import('./components/achievement/reportCard.vue')//高考成绩
const Competition = () => import('./components/achievement/competition.vue')//竞赛获奖
const Education = () => import('./components/achievement/education.vue')//教研成果教师教辅招聘
const AddHonour = () => import('./components/achievement/addHonour.vue')//添加学校荣誉
const AddReport = () => import('./components/achievement/addReport.vue')//添加高考成绩
const AddCompetition = () => import('./components/achievement/addCompetition.vue')//添加竞赛获奖
const AddEducation = () => import('./components/achievement/addEducation.vue')//添加教研成果
/*师资介绍*/
const Expert = () => import('./components/publicity/Expert.vue')//专家引领
const Teacher = () => import('./components/publicity/Teacher.vue')//名师执教
/*招生信息*/
const NewStudent = () => import('./components/student/NewStudent.vue')//高一新生
const JoinStudent = () => import('./components/student/JoinStudent.vue')//插班生
const ReturnStudent = () => import('./components/student/ReturnStudent.vue')//高考复读生
/*招聘信息*/
const RecruitTeacher = () => import('./components/recruit/RecruitTeacher.vue')//教师教辅招聘->专职教师招聘
const RecruitEmployee = () => import('./components/recruit/RecruitEmployee.vue')//职工招聘->教辅职工招聘
const RecruitManager = () => import('./components/recruit/RecruitManager.vue')//管理人员招聘
/*教育科研*/
const GuideWork = () => import('./components/education/guideWork.vue')//指导处工作
const Duty = () => import('./components/education/duty.vue')//职责
const CourseConstruction = () => import('./components/education/courseConstruction.vue')//课程建设
const TeacherGrowth = () => import('./components/education/teacherGrowth.vue')//教师成长
const SectionConstruction = () => import('./components/education/sectionConstruction.vue')//科组建设
const EfficientClass = () => import('./components/education/efficientClass.vue')//高效课堂
const ResearchProject = () => import('./components/education/researchProject.vue')//课题研究
/*德育之窗*/
const IdeologyDuty = () => import('./components/moralEducation/ideologyDuty.vue')//思政职责
const AddDuty = () => import('./components/moralEducation/addDuty.vue')//添加思政职责
const MoralComposition = () => import('./components/moralEducation/moralComposition.vue')//立德铸魂
const AddMoralComposition = () => import('./components/moralEducation/addMoralComposition.vue')//添加立德铸魂
const Pedestrian = () => import('./components/moralEducation/pedestrian.vue')//行者追光
const AddPedestrian = () => import('./components/moralEducation/addPedestrian.vue')//添加行者追光
const MoralEducationResearch = () => import('./components/moralEducation/moralEducationResearch.vue')//德育科研
const AddEducationResearch = () => import('./components/moralEducation/addEducationResearch.vue')//添加德育科研


const ueditor = () => import('./components/common/ueditor_wrap.vue')//
const useUeditor = () => import('./components/moralEducation/UseUeditor.vue')//
/*联系我们*/
const Feedback = () => import('./components/help/Feedback.vue')

Vue.use(VueRouter)

const routes = [
  {path: '/',redirect: '/login'},
  {path: '/login',component: Login},
  //{path: '/resetPwd',component: ResetPwd},
  {path: '/error',component: Error},
  {path: '/home',component: Home,redirect: '/welcome',
    children: [
        { path: '/ueditor', component: ueditor },
        { path: '/useUeditor', component: useUeditor },
        /*教育科研*/
        { path: '/guideWork', component: GuideWork },
        { path: '/duty', component: Duty },
        { path: '/courseConstruction', component: CourseConstruction },
        { path: '/teacherGrowth', component: TeacherGrowth },
        { path: '/sectionConstruction', component: SectionConstruction },
        { path: '/efficientClass', component: EfficientClass },
        { path: '/researchProject', component: ResearchProject },
        /*德育之窗*/
        { path: '/ideologyDuty', component: IdeologyDuty },
        { path: '/moralEducation/addDuty', component: AddDuty },
        { path: '/moralComposition', component: MoralComposition },
        { path: '/moralComposition/addMoralComposition', component: AddMoralComposition },
        { path: '/pedestrian', component: Pedestrian },
        { path: '/moralComposition/addPedestrian', component: AddPedestrian },
        { path: '/moralEducationResearch', component: MoralEducationResearch },
        { path: '/moralComposition/addEducationResearch', component: AddEducationResearch },

        { path: '/welcome', component: Welcome },
        { path: '/user', component: User },
        { path: '/module', component: Module },
        { path: '/Role', component: Role },
        { path: '/password', component: Password },
        /*学校首页*/
        { path: '/homePhoto', component: HomePhoto },
        { path: '/apply', component: Apply },
        /*学校概况*/
        { path: '/school', component: School },
        { path: '/school/addSchool', component: AddSchool },
        { path: '/manage', component: Manage },
        { path: '/sendword', component: Sendword },
        { path: '/course', component: Course },
        { path: '/publicity', component: Publicity },
        { path: '/view', component: View },
        /*校园动态*/
        { path: '/news', component: News },
        { path: '/news/addNews', component: AddNews },
        { path: '/notice', component: Notice },
        { path: '/news/addNotice', component: AddNotice },
        /*党建园地*/
        { path: '/partyShow', component: PartyShow },
        // { path: '/partyMien', component: PartyMien },
        { path: '/partyWorld', component: PartyWorld },
        { path: '/party/addPartyWorld', component: AddPartyWorld },
        /*办学成果*/
        { path: '/schoolHonour', component: SchoolHonour },
        { path: '/reportCard', component: ReportCard },
        { path: '/competition', component: Competition },
        { path: '/education', component: Education },
        { path: '/achievement/addHonour', component: AddHonour },
        { path: '/achievement/addReport', component: AddReport },
        { path: '/achievement/addCompetition', component: AddCompetition },
        { path: '/achievement/addEducation', component: AddEducation },
        /*师资介绍*/
        { path: '/expert', component: Expert },
        { path: '/teacher', component: Teacher },
        /*招生信息*/
        { path: '/newStudent', component: NewStudent },
        { path: '/joinStudent', component: JoinStudent },
        { path: '/returnStudent', component: ReturnStudent },
        /*招聘信息*/
        { path: '/recruitTeacher', component: RecruitTeacher },
        { path: '/recruitEmployee', component: RecruitEmployee },
        { path: '/recruitManager', component: RecruitManager },

        /*联系我们*/
        { path: '/feedback', component: Feedback },
    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫：无token跳转登录页
router.beforeEach((to, from, next) => {
  // to将要访问的路径 from 从哪个路径跳转而来 next 是一个函数，代表放行
  // next()放行 next('/login')强制跳转指定页面
  if (to.path === '/login' || to.path === '/error' || to.path === '/resetPwd') {
    return next()
  } else {
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) {
      console.log('没有token')
      return next('/login') // 无token强制跳转登录页
    } else {
      console.log('有token')
      return next()
    }
  }
})

export default router
