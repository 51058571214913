<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
/*降低弹窗层级，为了秀米插件能正常显示*/
.v-modal {z-index: 1000 !important;}
.el-dialog__wrapper {z-index: 1001 !important;}
</style>
